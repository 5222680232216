import React from "react";

import { Navbar } from "./nav";

const Header = () => (
    <header>
        <Navbar></Navbar>
    </header>
);

export default Header;
