import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import styled from "styled-components";

const SearchBox = styled.input`
    max-width: 460px;
    border-radius: 3px;
    padding: 6px 9px 5px;
    font-size: 16px;
    background: #e8e7e7;
    color: #0a0a0a;
    border: 1px solid lightgrey;
    margin: 0 15px;
    &:focus {
        border: 1px solid #c33dd4;
    }
`;

const SearchResults = styled.div`
    position: absolute;
    right: 20px;
    background-color: white;
    border-radius: 10px;
    border: 1px solid #c33dd4;
    padding: 20px;
`;

const PoweredBy = styled.div`
    position: relative;
    font-size: 12px;
    text-align: right;
`;

export function Search() {
    const [query, setQuery] = useState(null);
    const [results, setResults] = useState([]);
    useEffect(() => {
        if (window.__LUNR__) {
            window.__LUNR__.__loaded.then((lunr) => {
                if (query) {
                    const refs = lunr.en.index.search(query);
                    const posts = refs.map(({ ref }) => lunr.en.store[ref]);
                    setResults(posts);
                }
            });
        }
    }, [query]);
    return (
        <>
            <SearchBox
                onChange={(e) => setQuery(e.target.value)}
                value={query}
                placeholder="Search... 🔎"
            ></SearchBox>
            {query && !!results.length && (
                <SearchResults>
                    {results.slice(0, 10).map((p) => {
                        return (
                            <Link to={p.path}>
                                <h5>{p.title}</h5>
                            </Link>
                        );
                    })}
                    <PoweredBy>Search Powered By Electrons&trade;</PoweredBy>
                </SearchResults>
            )}
        </>
    );
}
