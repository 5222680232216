import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql, Link } from "gatsby";
import styled from "styled-components";
// import Pusher from "pusher-js";

import { mobileS, mobileL } from "../components/breakpoints";
import Header from "./header";
import "./layout.css";

const MainContainer = styled.div`
    margin: 0 auto;
    @media${mobileS} {
        padding: 75px 1.0875rem 0;
    }
    @media${mobileL} {
        padding: 62px 1.0875rem 0;
    }
`;
const Main = styled.main`
    padding-top: 25px;
`;
const Footer = styled.footer`
    margin-top: 20px;
    padding: 10px 0;
    border-top: 2px solid #c33dd4;
`;
const Layout = ({ children }) => {
    const data = useStaticQuery(graphql`
        query SiteTitleQuery {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `);
    return (
        <>
            <Header siteTitle={data.site.siteMetadata.title} />
            <MainContainer>
                <Main>{children}</Main>
                <Footer>
                    © {new Date().getFullYear()} unicorn.computer |{" "}
                    <Link to="/about">about</Link> |{" "}
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://hello.unicorn.computer/subscribe"
                    >
                        subscribe to updates
                    </a>
                </Footer>
            </MainContainer>
        </>
    );
};

Layout.propTypes = {
    children: PropTypes.node.isRequired
};

export default Layout;
