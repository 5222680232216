import React from "react";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import { Link } from "gatsby";

import { mobileS, tablet } from "../components/breakpoints";

const Wrapper = styled.div`
    display: inline;
    font-family: "Inter";
    display: flex;
    padding: 5px;
    text-decoration: none;
    & img {
        transform: rotate(0deg);
        transform-origin: center bottom;
    }
    &:hover img {
        transform: rotate(10deg);
        transform-origin: center bottom;
    }
    @media ${mobileS} {
        font-size: 28px;
    }
    @media ${tablet} {
        font-size: 32px;
    }
`;
const Unicorn = styled.span`
    color: #c33dd4;
    font-weight: 900;
`;
const Computer = styled.span`
    font-weight: 500;
`;
export function Logo() {
    const data = useStaticQuery(graphql`
        query {
            placeholderImage: file(relativePath: { eq: "unicorn.png" }) {
                childImageSharp {
                    fluid(maxWidth: 100) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `);
    return (
        <Link
            style={{
                color: "black",
                textDecoration: "none",
                boxShadow: "none"
            }}
            to={"/"}
        >
            <Wrapper>
                <Unicorn>unicorn</Unicorn>.<Computer>computer</Computer>
                <Img
                    style={{
                        verticalAlign: "middle",
                        height: 32,
                        width: 32
                    }}
                    fluid={data.placeholderImage.childImageSharp.fluid}
                />
            </Wrapper>
        </Link>
    );
}
