import React, { useState } from "react";
import styled from "styled-components";

import { Search } from "../components/search/SearchBox";
import { mobileS, tablet } from "../components/breakpoints";
import { Logo } from "./Logo";
import { useInterval } from "./hooks/useInterval";

const Nav = styled.nav`
    position: fixed;
    display: flex;
    width: 100%;
    // justify-content: space-between;
    border-bottom: 3px solid #c33dd4;
    align-items: center;
    margin: 0 0 10px;
    z-index: 1;
    background-color: white;
    @media${mobileS} {
        min-height: 70px;
        flex-direction: column;
        input {
            display: none;
        }
    }
    @media${tablet} {
        min-height: 45px;
        flex-direction: row;
        padding: 5px;
        input {
            display: initial;
        }
    }
`;

export const Motto = styled.h2`
    font-size: 14px;
    font-weight: 300;
    padding: 0 10px;
    margin: 0 10px;
    @media ${mobileS} {
        border-left: none;
    }
    @media ${tablet} {
        border-left: 1px solid grey;
    }
`;
const phrases = [
    "But... will it scale?",
    "Always be scaling",
    "Always be launching",
    "optionality",
    "Be evil and break things",
    "Move fast and fake things",
    "Cultural fit",
    "Be SaaSy",
    "taker schedule",
];

const AlignRight = styled.div`
    @media${mobileS} {
        margin-left: 0;
    }
    @media${tablet} {
        margin-left: auto;
    }
`;

const SubScribeLink = styled.a`
    @media${mobileS} {
        position: absolute;
        right: 10px;
        top: 10px;
    }
    @media${tablet} {
        position: initial;
        float: none;
    }
`;

function getPhrase() {
    return phrases[Math.floor(Math.random() * phrases.length)];
}
export function Navbar() {
    const [phrase, setPhrase] = useState("the most 10x tech news source");
    useInterval(() => setPhrase(getPhrase()), 4500);
    return (
        <Nav>
            <Logo />
            <Motto>{phrase}</Motto>
            <AlignRight>
                <SubScribeLink
                    rel="noopener noreferrer"
                    style={{ textDecoration: "none" }}
                    href="/about"
                >
                    <span
                        style={{ fontSize: 30, verticalAlign: "middle" }}
                        aria-label="newsletter"
                        role="img"
                    >
                        ❓
                    </span>
                </SubScribeLink>
                <SubScribeLink
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: "none" }}
                    href="https://hello.unicorn.computer/subscribe"
                >
                    <span
                        style={{
                            fontSize: 30,
                            verticalAlign: "middle",
                            paddingLeft: 20,
                        }}
                        aria-label="newsletter"
                        role="img"
                    >
                        💌
                    </span>
                </SubScribeLink>
                <Search />
            </AlignRight>
        </Nav>
    );
}
